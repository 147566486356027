<template>
  <div class="sign">
    <div class="scroll">
      <div class="information">
        <!-- <div class="head">
          <p>
            <span>车牌号：</span>
            <span>{{ detailForm.cph }}</span>
          </p>
          <p>
            <span>所属车队：</span>
            <span>{{ detailForm.vehicleGroupName }}</span>
          </p>
        </div> -->
        <p class="split-line"></p>
        <div class="body">
          <el-table
            :data="detailForm.signDetailDTOList"
            max-height="270px"
            class="no-blank"
          >
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column
              v-if="params.type===0"
              prop="driverName"
              label="驾驶员姓名"
              width="160"
            ></el-table-column>
            <el-table-column
              v-if="params.type===1"
              prop="cph"
              label="车牌号"
              width="160"
            ></el-table-column>
            <el-table-column
              prop="inTime"
              label="登签时间"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="outTime"
              label="退签时间"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <!-- <el-table-column prop="address" label="运营时长" :show-overflow-tooltip="true"></el-table-column> -->
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryLoginLogDetail } from '@/api/lib/taxi-api.js'
export default {
  props: {
    params: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      detailForm: {
        cph: '',
        vehicleGroupName: '',
        signDetailDTOList: []
      }
    }
  },
  methods: {
    getDetail () {
      queryLoginLogDetail(this.params).then(res => {
        if (res.code === 1000) {
          if(res.data.length>0){
            this.detailForm.cph = res.data[0].cph ||'';
            this.detailForm.vehicleGroupName = res.data[0].companyName ||'';
            this.detailForm.signDetailDTOList = res.data;
          }
        }
      })
    }
  },
  created () {
    this.getDetail()
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.sign {
  height: calc(60vh - 149px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @include themify() {
    .fixed {
      flex: 1;
    }
    .scroll {
      overflow-y: auto;
    }
    .title {
      color: themed("n7");
      font-size: 14px;
      margin-bottom: 16px;
    }
    .information {
      background-color: themed("n1");
      padding: 12px;
      margin-bottom: 16px;
      .head {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        color: themed("n8");
        font-size: 14px;
        margin-bottom: 16px;
        p:nth-of-type(1) {
          margin-bottom: 16px;
        }
      }
      .split-line {
        // border-bottom: 1px solid;
        border-bottom-color: themed("n4");
        margin: 0 -12px 12px;
      }
    }
  }
}
</style>
