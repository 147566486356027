var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "complaint" }, [
    _c("div", { staticClass: "scroll" }, [
      _c("p", { staticClass: "title" }, [_vm._v("营运详情")]),
      _c("div", { staticClass: "information" }, [
        _c("div", { staticClass: "head" }, [
          _c("p", [
            _c("span", [_vm._v("车牌号：")]),
            _c("span", [_vm._v(_vm._s(_vm.detailForm.cph))])
          ]),
          _c("p", [
            _c("span", [_vm._v("所属车队：")]),
            _c("span", [_vm._v(_vm._s(_vm.detailForm.companyName))])
          ])
        ]),
        _c("p", { staticClass: "split-line" }),
        _c(
          "div",
          { staticClass: "body" },
          [
            _c(
              "el-table",
              {
                staticClass: "no-blank",
                attrs: {
                  data: _vm.detailForm.alarmDetailVOs,
                  "max-height": "270px"
                }
              },
              [
                _c("el-table-column", {
                  attrs: { type: "index", label: "序号" }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "alarmSignTime",
                    label: "驾驶员姓名",
                    width: "160"
                  }
                }),
                _c("el-table-column", {
                  attrs: { prop: "velocity", label: "上车时间" }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "position",
                    label: "下车时间",
                    "show-overflow-tooltip": true
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "address",
                    label: "投诉内容",
                    "show-overflow-tooltip": true
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }