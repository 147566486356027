var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sign" }, [
    _c("div", { staticClass: "scroll" }, [
      _c("div", { staticClass: "information" }, [
        _c("p", { staticClass: "split-line" }),
        _c(
          "div",
          { staticClass: "body" },
          [
            _c(
              "el-table",
              {
                staticClass: "no-blank",
                attrs: {
                  data: _vm.detailForm.signDetailDTOList,
                  "max-height": "270px"
                }
              },
              [
                _c("el-table-column", {
                  attrs: { type: "index", label: "序号" }
                }),
                _vm.params.type === 0
                  ? _c("el-table-column", {
                      attrs: {
                        prop: "driverName",
                        label: "驾驶员姓名",
                        width: "160"
                      }
                    })
                  : _vm._e(),
                _vm.params.type === 1
                  ? _c("el-table-column", {
                      attrs: { prop: "cph", label: "车牌号", width: "160" }
                    })
                  : _vm._e(),
                _c("el-table-column", {
                  attrs: {
                    prop: "inTime",
                    label: "登签时间",
                    "show-overflow-tooltip": true
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "outTime",
                    label: "退签时间",
                    "show-overflow-tooltip": true
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }