<template>
  <div class="complaint">
    <div class="scroll">
      <p class="title">营运详情</p>
      <div class="information">
        <div class="head">
          <p>
            <span>车牌号：</span>
            <span>{{ detailForm.cph }}</span>
          </p>
          <p>
            <span>所属车队：</span>
            <span>{{ detailForm.companyName }}</span>
          </p>
        </div>
        <p class="split-line"></p>
        <div class="body">
          <el-table :data="detailForm.alarmDetailVOs" max-height="270px" class="no-blank">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="alarmSignTime" label="驾驶员姓名" width="160"></el-table-column>
            <el-table-column prop="velocity" label="上车时间"></el-table-column>
            <el-table-column prop="position" label="下车时间" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="address" label="投诉内容" :show-overflow-tooltip="true"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['alarmID', 'alarmList', 'alarmStatus', 'isGlobalAlarm'],
  data () {
    return {
      detailForm: {
        alarmBaseVO: {
          cph: '',
          companyName: '',
          driverName: '',
          drivingLicense: '',
          alarmName: '',
          alarmLevelStr: ''
        }
      }
    }
  },
  methods: {
    getDetail () {
    }
  },
  created () {
    this.getDetail()
  },
  mounted () {
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
.complaint {
  height: calc(60vh - 149px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @include themify() {
    .fixed {
      flex: 1;
    }
    .scroll {
      overflow-y: auto;
    }
    .title {
      color: themed("n7");
      font-size: 14px;
      margin-bottom: 16px;
    }
    .information {
      background-color: themed("n1");
      padding: 12px;
      margin-bottom: 16px;
      .head {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        color: themed("n8");
        font-size: 14px;
        margin-bottom: 16px;
        p:nth-of-type(1) {
          margin-bottom: 16px;
        }
      }
      .split-line {
        border-bottom: 1px solid;
        border-bottom-color: themed("n4");
        margin: 0 -12px 12px;
      }
    }
  }
}
</style>
