<template>
  <div class="service-evaluation">
    <div class="scroll">
      <div class="information">
        <!-- <div class="head">
          <p>
            <span>车牌号：</span>
            <span>{{ detailForm.cph }}</span>
          </p>
          <p>
            <span>所属车队：</span>
            <span>{{ detailForm.vehicleGroupName }}</span>
          </p>
        </div> -->
        <p class="split-line"></p>
        <div class="body">
          <el-table
            :data="detailForm.operateDetailDTOList"
            max-height="270px"
            class="no-blank"
          >
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column
              v-if="params.credentialsNo"
              prop="driverName"
              label="驾驶员姓名"
              width="160"
            ></el-table-column>
            <el-table-column
              v-if="params.vehicleNo"
              prop="cph"
              label="车牌号"
              width="110"
            ></el-table-column>
            <el-table-column
              prop="operationMileageKilometer"
              label="行驶里程（km）"
            ></el-table-column>
            <!-- <el-table-column
              prop="operationMileageKilometer"
              label="行驶里程（km）"
              :formatter="formatMileage"
            ></el-table-column> -->
            <el-table-column
              prop="valuationTimeStr"
              label="计价时长"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="operatingAmount"
              label="营收金额"
              :show-overflow-tooltip="true"
              :formatter="formatMoney"
            ></el-table-column>
            <el-table-column
              prop="evaluateDate"
              label="评价时间"
              :show-overflow-tooltip="true"
              ></el-table-column
            >
            <el-table-column
              prop="evaluateStatus"
              label="评价分数"
              width="120"
              :show-overflow-tooltip="true"
              ></el-table-column
            >
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryServiceDataDetail } from '@/api/lib/taxi-api.js'
export default {
  props: {
    params: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      detailForm: {
        cph: '',
        vehicleGroupName: '',
        operateDetailDTOList: []
      }
    }
  },
  methods: {
    getDetail () {
      queryServiceDataDetail(this.params).then(res => {
        if (res.code === 1000) {
          if(res.data.length>0){
            this.detailForm.cph = res.data[0].cph ||'';
            this.detailForm.vehicleGroupName = res.data[0].companyName ||'';
            this.detailForm.operateDetailDTOList = res.data;
          }          
        }
      })
    },
    /** km */
    formatMileage (row, column, cellValue) {
      return cellValue == null || cellValue === '' ? '-' : `${this.formatNumber(cellValue / 1000)}KM`
    },
    /** 数据% */
    formatNumber (cellValue, number) {
      number = number || 1
      return parseInt(cellValue * number) < parseFloat(cellValue * number) ? (cellValue * number).toFixed(2) : cellValue * number
    },
    /** Money */
    formatMoney (cellrow, column, cellValue) {
      return cellValue == null || cellValue === '' ? '-' : `${this.formatNumber(cellValue / 100)}元`
    }
  },
  created () {
    this.getDetail()
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.service-evaluation {
  height: calc(60vh - 149px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @include themify() {
    .fixed {
      flex: 1;
    }
    .scroll {
      overflow-y: auto;
    }
    .title {
      color: themed("n7");
      font-size: 14px;
      margin-bottom: 16px;
    }
    .information {
      background-color: themed("n1");
      padding: 12px;
      margin-bottom: 16px;
      .head {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        color: themed("n8");
        font-size: 14px;
        margin-bottom: 16px;
        p:nth-of-type(1) {
          margin-bottom: 16px;
        }
      }
      .split-line {
        // border-bottom: 1px solid;
        border-bottom-color: themed("n4");
        margin: 0 -12px 12px;
      }
    }
  }
}
</style>
